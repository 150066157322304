.stats_section {
  padding: 120px 7vw 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: calc(130px + 5vw);
  box-sizing: border-box;
}

.plate_on_flour {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  min-width: 200px;
  min-height: 185px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.first_two_plates{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.main_plate_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  h3{
    z-index: 2;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
}
.first_plate {
}

.second_plate {
  margin-left: calc(130px + 5vw);
}

.third_plate {
  margin-top: 90px;
  margin-left: -210px;
}
.flour1_img {
  width: 20vw;
  height: 20vw;
  min-width: 200px;
  background-image: url("../../../../assets/backgronds/home/statsSection/flour1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}
.flour2_img {
  width: 20vw;
  height: 20vw;
  min-width: 200px;
  background-image: url("../../../../assets/backgronds/home/statsSection/flour2.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}
.flour3_img {
  width: 20vw;
  height: 20vw;
  min-width: 200px;
  background-image: url("../../../../assets/backgronds/home/statsSection/flour3.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}
.flour_img {
  width: 100% ;
  min-width: 200px;
}

.plate_img {
  width: calc(80px + 8vw) ;
  min-width: 100px;
  height: calc(80px + 8vw) ;
  min-height: 100px;
  position: absolute;
  background-image: url("../../../../assets/backgronds/home/statsSection/plate1.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 1;
}
.fstx1_img{
  position: absolute;
  max-width: 523px;
  min-height: 50px;
  width: calc(200px + 25vw);
  margin-left: calc(300px + 25vw);
  margin-top: 50px;

}

.fstx2_img{
  position: absolute;
  width: calc(150px + 15vw);
  max-width: 425px;
  margin-bottom: 50px;
  margin-left:calc(300px + 20vw);
}

.stats_number{
  position: absolute;
  z-index: 3;

  font-weight: 700;
  font-size: 62px;
  line-height: 76px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.rosemary{
  width: 20vw;
  height: 30vw;
  max-height: 410px;
  max-width: 260px;
  position: absolute;
  left: 0;
  margin-top: 300px;


background-image: url("../../../../assets/backgronds/home/statsSection/rosemary.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}


/////////////mobile////////////////


.stats_section_mobile {
  padding: 50px 5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 140px;
  box-sizing: border-box;
}

.plate_on_flour_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 126.19px;
  height: 113.5px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.first_two_plates_mobile{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main_plate_div_mobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 126.19px;
  height: 113.5px;
  gap: 0;
  h3{
    position: absolute;
    z-index: 2;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}
.first_plate_mobile {
  h3{
    margin-left: 170px;
  }
}

.second_plate_mobile {
  margin-left: 100px;
  h3{
    margin-left: 170px;
  }
}

.third_plate_mobile {
  margin-top: 40px;
  margin-left: -30px;

  h3{
    margin-right: 140px;
  }
}

.fstx1_img_mobile{
  position: absolute;
  width: 200px;
  height: 105.93px;
  margin-left: 250px;
  margin-top: 20px;

  background-image: url("../../../../assets/backgronds/home/statsSection/fstx1.png");
  background-repeat: no-repeat;
  background-size: 200px 105.93px;
}

.fstx2_img_mobile{
  position: absolute;
  width: 150px;
  height:  85.76px;
  margin-left: 220px;
  margin-bottom: 10px;

  background-image: url("../../../../assets/backgronds/home/statsSection/fstx2.png");
  background-repeat: no-repeat;
  background-size: 150px  85.76px;
}



.flour1_img_mobile {
  width: 126.19px;
  height: 113.5px;
  background-image: url("../../../../assets/backgronds/home/statsSection/flour1.webp");
  background-repeat: no-repeat;
  background-size: 126px 113px;
}
.flour2_img_mobile {
  width: 126.19px;
  height: 113.5px;
  background-image: url("../../../../assets/backgronds/home/statsSection/flour2.webp");
  background-repeat: no-repeat;
  background-size: 126px 113px;
}
.flour3_img_mobile {
  width: 126.19px;
  height: 113.5px;
  background-image: url("../../../../assets/backgronds/home/statsSection/flour3.webp");
  background-repeat: no-repeat;
  background-size: 126px 113px;
}

.plate_img_mobile {
  width: 81.37px;
  height: 82.69px;
  position: absolute;
  background-image: url("../../../../assets/backgronds/home/statsSection/plate1.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 1;
}


.stats_number_mobile{
  position: absolute;
  z-index: 1;

  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.rosemary_mobile{
  width: 20vw;
  max-width: 260px;
  position: absolute;
  left: 0;
  margin-top: 250px;
  height: 30vw;
  max-height: 410px;


  background-image: url("../../../../assets/backgronds/home/statsSection/rosemary.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
