.cart_section {
  margin-top: 145px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 75px;
  padding: 100px 7vw 120px 7vw;
  box-sizing: border-box;
  background-image: url("../../../../assets/backgronds/cart/cartBg.webp");
  background-size: 100%;
  background-position: 0 -10vw;
}

.section_heading {
  position: relative;
  left: -7vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}

.cart_item_table_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.cart_items_table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px
}

.cart_item_heading_div {
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.cart_item_div {
  background: #FFFFFF;
  height: 120px;
  box-shadow: -2px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 8.39381px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.cart_item_heading {
  display: flex;
  justify-content: center;
  align-items: center;


  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;


}

.small_div {
  width: 10%;
}

.medium_div {
  width: 17.5%;
}

.big_div {
  width: 27.5%;
  justify-content: flex-start;
}


.cart_item {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 24px;
  line-height: 100.9%;
  /* or 24px */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #000000;
}

.product_image_name {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;

  span {
    width: 70%;
    text-align: start;
  }
}

.product_images {
  cursor: pointer;
  width: 90px;
}

.price_calculator {
  width: 45%;
}

.nav_btn_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.back_shop_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;

  background: #FFFFFF;
  border-radius: 5px;
  padding: 15px 26px;


  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #730101;

}

.checkbox_heading_div{
  margin-right: 20px;
}

.checkbox_div{
  height: 100%;
  margin-right: 20px;
  background: #730101;
  border-radius: 5px 0 0 5px;
}

.checkout_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #730101;
  border-radius: 5px;
  border: none;
  padding: 15px 26px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.empty_cart_div {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

}

.empty_cart_text {
  font-weight: 800;
  font-size: 25px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #201E1E;
}

.total_price_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    box-sizing: border-box;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
}

.total_price {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    padding: 0 20px;
    position: absolute;

  }
}

.checkbox  {

  width: 30px;
  height: 30px;
  box-sizing: border-box;
  accent-color: #730101;
  padding-right: 0;
  margin: 0;
}
.checkbox:checked ~ .checkmark {
  background-color: #198519 !important;
}