.main_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 75px;
  margin-top: 145px;
  padding: 100px 0 120px;
}

.section_heading{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h1 {
    white-space: break-spaces;
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: calc(12px + 1.8vw) !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: start;
    color: #FFFFFF;
    z-index: 1;
  }
  hr{

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}

.order_details {
  width: 830px;
  max-height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 4px 4px 10px rgba(255, 255, 255, 0.25);
  border-radius: 10px;

  box-sizing: border-box;
  padding: 33px;

  gap: 40px;
}

.order_details_heading{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;

  span{
    margin-right: 0.5rem;
    color: #730101;
  }

}

.input_section {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 65px;
}

.input_div {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: nowrap;

  label {
    white-space: break-spaces;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #730101;
  }

  input {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 5px;


    max-width: 180px;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #000000;
    padding: 16px 5px 16px 12px;
    box-sizing: border-box;
  }
}
.total_price_div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: #000000;
}
.total_price{
  display: flex;
  justify-content: center;
  align-items: center;


  span{
    position: absolute;

    margin-right: 15px;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
}

.section_paragraph{
  white-space: break-spaces;
  margin: 0;
  padding: 0;
  max-width: 944px;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #FFFFFF;
}

.backBtn{
  margin-top: -25px;
  cursor: pointer;
  background: #FFFFFF;
  border: none;
  border-radius: 5px;

  box-sizing: border-box;
  padding: 15px 42px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #730101;
}


/////////////mobile//////////////////////////////////////

.main_section_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;

  padding: 150px 0 45px;
}

.section_heading_mobile{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  h1 {
    white-space: break-spaces;
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 22px;
    line-height: 120%;
    /* or 29px */

    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #FFFFFF;

    z-index: 1;
  }
  hr{

    height: 1px;
    width: 32px;
    background: #FFFFFF;
    position: relative;
  }
}

.order_details_mobile {
  width: 100%;
  max-height: 182px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 4px 4px 10px rgba(255, 255, 255, 0.25);
  border-radius: 10px;

  box-sizing: border-box;
  padding: 16px 24px;

  gap: 18px;
}

.order_details_heading_mobile{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;

  span{
    margin-right: 0.5rem;
    color: #730101;
  }

}

.input_section_mobile{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.input_div_mobile {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: nowrap;

  label {
    white-space: break-spaces;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #730101;
  }

  input {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 5px;


    width: 100px;
    height: 27px;

    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #000000;

    padding: 9px 5px 9px 5px;
    box-sizing: border-box;
  }
}
.total_price_div_mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;

  font-weight: 600;
  font-size: 9.94612px;
  line-height: 12px;
  letter-spacing: 0.01em;

  color: #000000;

}
.total_price_mobile{
  display: flex;
  justify-content: center;
  align-items: center;


  span{
    position: absolute;

    margin-right: 15px;

    font-weight: 700;
    font-size: 9.94612px;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }

  img{
    width: 71.13px;
    height: 24.19px;
  }
}

.section_paragraph_mobile{
  white-space: break-spaces;
  margin: 0;
  padding: 0 5vw;
  max-width: 380px;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;

}

.backBtn_mobile{
  cursor: pointer;
  border: none;
  background: #FFFFFF;
  border-radius: 3.89653px;

  box-sizing: border-box;
  padding: 12px 35px;

  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #730101;

}