.review_section{
    width: 100%;
    max-width: 468px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;


    font-weight: 400;
    font-size: 16px;
    /* or 25px */

    letter-spacing: 0.01em;
    color: #FFFFFF;

}

.review_section_mobile{
    font-weight: 400;
    font-size: 15px;
    line-height: 140.2%;
    /* or 21px */

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
}