.slider_container {
  margin-top: -50px;
  box-sizing: border-box;
  position: relative;
  z-index: 1 !important;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.slider_container_mobile {
  margin-top: 20px;
  overflow: hidden !important;
  box-sizing: border-box;
  position: relative;

  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}
