
.aboutUs_main_section {

  margin-top: 124px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden !important;
}


.section_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw 0 0;
  box-sizing: border-box;

  h2 {
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
    /* identical to box height, or 48px */

    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.faq_section_heading {
  margin: 120px 0 75px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;


  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    text-transform: capitalize !important;
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;


    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}


.second_section {
  display: flex;
  flex-direction: column;
}

.about_img {
  max-width: 564px;
  width: 39vw;
}


.board_img {
  width: 100%;

  z-index: -1;
}

.chili_img {
  position: absolute;

  margin-bottom: -120px;
  margin-right: -30px;
}

.paragraph_div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  overflow-x: hidden !important;
  width: 340px;
  height: 250px;
  padding: 15px;
  background-image: url("../../../../assets/backgronds/aboutUs/board.png") ;
  background-repeat: no-repeat;
  background-size: 100% 100%;


  p {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.01em;

    color: #FFFFFF;
  }
}