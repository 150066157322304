.search_input_div{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 16px;
  flex: 1;
  max-width: 267px;
  width: 18vw;
  height: 44px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;

  button{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
  }
}

.search_input_div_mobile{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 16px;
  flex: 1;
  max-width: 285px;
  width: 100%;
  height: 44px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;

  button{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;
  }
}

.position_relative{
  position: relative !important;
}

.input_desktop{
  width: 100%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  background-color: inherit;
  background: none;
  border: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.dropdown_menu {
  z-index: 10;
  margin-top: 8px;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  box-sizing: border-box;
  gap: 10px;
  height: min-content;
  min-height: min-content;
  max-height: 105px;
  overflow-y: auto;
}
.dropdown_menu_mobile{
  z-index: 10;
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  box-sizing: border-box;
  gap: 10px;
  height: min-content;
  min-height: min-content;
  max-height: 105px;
  overflow-y: auto;
}

.dropdown_menu::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;

  background-color: inherit;
}

.dropdown_menu::-webkit-scrollbar
{
  border-radius: 5px;
}

.dropdown_menu::-webkit-scrollbar-thumb
{
  width: 16px;
  height: 12px;
  background: #FFFFFF;
}
.dropdown_menu::-webkit-scrollbar-button {
  display: block;
  background-color: inherit;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.dropdown_menu::-webkit-scrollbar-button:vertical:start:increment {
  display: none;
}

.dropdown_menu::-webkit-scrollbar-button:vertical:start:decrement {

  background-image: url("../../../../assets/icons/layout/navbar/scrollBarUp.svg");
}

.dropdown_menu::-webkit-scrollbar-button:vertical:end:increment {
  background-image:url("../../../../assets/icons/layout/navbar/scrollBarDown.svg");
}

.dropdown_menu::-webkit-scrollbar-button:vertical:end:decrement {
  display: none;
}
.search_suggestions{
  cursor: pointer;
  padding: 1px 13px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.search_suggestions:hover{
  background: #730101;
}
.search_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: min-content;
  width: 100%;
  gap: 10px;
}

.second_section_second_btn{
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  gap: 5px;
  font-style: normal;
  font-size: 14px ;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.01em;

  text-transform: capitalize;

  color: #FFFFFF;
}

.btn_icon_div{
  width: 3vw;
  height: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}




.black_btn_heading{
  color: #223D40 !important;
  font-weight: 400 !important;
}

.search_input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  background: none;


  transition: all 0.5s;
  font-size: 18px;
  font-style: normal;
  transform-origin: right;
  transform: scaleX(1);
}


.search_input_mobile_absolute_active{
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 85%;
  left: 0;
  padding: 0 7vw 0 7vw;
  z-index: 2 !important;
}

.search_input_mobile_absolute{
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 85%;
  left: 0;
  padding: 0 7vw 0 7vw;
  z-index: -222222222 !important;
}

.search_input_mobile {
  box-sizing: border-box;
  flex: 1;
  width: 3vw;
  height: 3.3vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  transition: all 0.5s;
  transform-origin: left;
  transform: scaleX(0);
}

.search_input_active_mobile {
  box-sizing: border-box;
  padding: 0;
  flex: 1;
  width: 100%;
  min-width: 110px;
  height: 3.24vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  transition: all 0.5s;
  font-size: 18px;
  font-style: normal;
  transform-origin: left;
  transform: scaleX(1);
}





