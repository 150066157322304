.main_section{
  margin-top: 145px;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 100px 0 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 75px;

  background-image: url("../../../assets/backgronds/settings/settingsBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.section_heading{
  padding: 0;
  margin: 0;
  font-weight: 800;
  font-size: 48px;
  line-height: 120%;
  /* identical to box height, or 58px */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.settings_section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.section_row{
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.tel_input_div{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 256px;
  height: 50px;
  background: none;
  border: 1px solid #FFFFFF;
  border-radius: 5px;


  padding: 16px 19px;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #FFFFFF;

  input {
    border: none !important;
    padding: 15px 15px 15px 5px !important;
  }
}

.tel_span{
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  letter-spacing: 0.02em !important;

  color: #FFFFFF !important;
}
.input_div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  label{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #FFFFFF;
  }

  input{
    width: 256px;
    height: 50px;
    background: none;
    border: 1px solid #FFFFFF;
    border-radius: 5px;

    box-sizing: border-box;

    padding: 16px 19px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #FFFFFF;
  }

  input:-webkit-autofill:focus,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {

    z-index: 1 !important;
    -webkit-text-fill-color: #FFFFFF !important;
    caret-color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height, or 117% */

    color: #FFFFFF !important;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:focus {
    outline: none !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.email_input {
  text-transform: lowercase !important;
}


.save_btn{
  cursor: pointer;
  box-sizing: border-box;
  padding: 15px 26px;

  border: none;
  background: #FFFFFF;
  border-radius: 5px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #730101;
}


.password_input_div{
  width: 256px;
  height: 50px;
  background: none;
  border: 1px solid #FFFFFF;
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  padding: 16px 10px 16px 19px;

  span{
    cursor: pointer;
  }
}

.password_input{
  width: 100% !important;
  background: none !important;
  border: none !important;
  border-radius: 5px;

  box-sizing: border-box;

  padding: 0 !important;
}

///////////mobile///////////////

.main_section_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 45px;

  box-sizing: border-box;
  padding: 150px 5vw 40px;

  background-image: url("../../../assets/backgronds/settings/settingsBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.section_heading_mobile{
  font-weight: 800;
  font-size: 8.5vw;
  line-height: 120%;
  /* identical to box height, or 48px */

  text-transform: capitalize;

  color: #FFFFFF;

}
.settings_section_mobile{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 23px;
}


.save_btn_mobile{
  cursor: pointer;
  box-sizing: border-box;
  padding: 11px 19px;

  margin-top: 7px;

  border: none;
  background: #FFFFFF;
  border-radius: 5px;

  font-weight: 700;
  font-size: 11.8429px;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #730101;

}

