.mySwiper{
  margin-top: 0 !important;
  overflow: hidden !important;
  box-sizing: border-box;
}

.display_none{
  display: none;
}


.sliderDiv{
  -webkit-transform: translate3d(0,0,0);
  border: none !important;
  box-sizing: border-box;
  display: flex;

  width: 144px;
  height: 230px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;

  img{
    width: 144px;
    height: 216px;
    transition: transform 2s;
    z-index: 1 !important;
    transform: scale(1.2);
    transform-origin: 50% 50%;

  }
}

.sliderDiv_inactive{
  -webkit-transform: translate3d(0,0,0);
  border: none !important;
  box-sizing: border-box;
  display: flex;

  width: 144px;
  height: 230px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  img{
    transition: transform 2s;
    width: 144px;
    height: 216px;
    z-index: 1 !important;
    filter: grayscale(100%);
  }
}

.slider_container{
  overflow: hidden !important;
  box-sizing: border-box;
  position: relative;

}



.category_btn{
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0,0,0);
  position: relative;
  width: 100%;
  height: 100%;
  min-width: fit-content;
  min-height: fit-content;
  padding: 0;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  background: none;


  h3{
    position: absolute;
    z-index: 2;
    margin-left: 130px;
    margin-bottom: 70px;
    -webkit-transform: translate3d(0,0,0);
    font-weight: 700;
    font-size: 11.2829px;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF
  }
}

