.footer {
    user-select: none;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    background: none;
    padding: 50px 7vw 30px 7vw ;
    border-top: 1px solid #FFFFFF;;
}

.footer_first_section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.footer_logo_section{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.footer_section{
    height: 72px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    a{
        cursor: pointer;
        text-decoration: none;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-transform: capitalize;

        color: #FFFFFF;
    }
}

.footer_social_section{
    align-items: flex-start;
    gap:20px
}

.footer_second_section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #FFFFFF;

}


.contact_section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #E8E8E8;

    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
}

.section_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    
    letter-spacing: 0.01em;
    text-transform: capitalize;
    
    color: #FFFFFF;
}

.section_btn{
    cursor: pointer;
    background: none;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
}

.insta_icon{
    user-select: none;
    cursor: pointer;
    width: 33px;
    height: 33px;

    background-image: url("../../../../assets/icons/layout/footer/instaIcon.png");
    background-repeat: no-repeat;
    background-size: 33px 33px;
}

.facebook_icon{
    user-select: none;
    cursor: pointer;
    width: 33px;
    height: 33px;

    background-image: url("../../../../assets/icons/layout/footer/facebookIcon.png");
    background-repeat: no-repeat;
    background-size: 33px 33px;
}