

.calculator_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.calculator_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}

.quantityDiv {
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 5px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-sizing: border-box;
  background: #730101;
  border-radius: 3px;
  border: none;

  span{
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;


    font-size: 15px;
    font-weight: bold;
  }
}

.quantity {
  width: 50px;
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF !important;
}

.price {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF !important;
}

.remove_item_btn {
  width: 93px;
  height: 31px;

  border: 0.820014px solid #FFFFFF !important;
  backdrop-filter: none !important;

  background: none;
  cursor: pointer;
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;



  span {
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    /* identical to box height */

    font-variant: small-caps;

    color: #FFFFFF !important;

  }
}

.btn_flex_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.add_btn {
  cursor: pointer;
  width: 93px;
  height: 31px;
  border: 0.820014px solid #FFFFFF;
  background: none;
  backdrop-filter: blur(2.05003px);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;

  span {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */

    text-transform: capitalize;

    color: #FFFFFF;
    white-space: nowrap !important;
  }
  img{
    width: 11px;
    height: 9px;
  }
}



.buy_now_btn {
  cursor: pointer;
  width: 93px;
  height: 31px;

  background: #730101;
  border: 0.820014px solid rgba(0, 0, 0, 0.08);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  span {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */

    text-transform: capitalize;
    white-space: nowrap;
    color: #FFFFFF;
  }
}