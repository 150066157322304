.main_order_section_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 150px
}

.payment_method_section_mobile{
  padding: 26px;
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #F3F3F3;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;;
}

.order_form_mobile{
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  background: #F3F3F3;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}


.cart_section{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  padding: 50px 5vw 45px ;
  background-image: url("../../../../assets/backgronds/checkout/checkoutMobileBg.webp");
  background-size:100%;
}

.first_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 45px;
}

.region_dropdown_toggle{
  cursor: pointer;
}


.section_heading {
  position: relative;
  left: -5vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
    /* identical to box height, or 48px */

    text-transform: capitalize;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 70px;
    background: #FFFFFF;
    position: relative;
  }
}

.order_heading{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;
  margin-bottom: 30px;

  img{
    width: 27px;
    height: 27px;
  }
}
.order_form_heading {
  width: 100%;
  position: relative;
  right: -5vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    width: min-content;
    margin: 0;
    font-weight: 800;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */

    text-transform: capitalize;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 60px;
    background: #FFFFFF;
    position: relative;
  }
}




.cash_less_idram_div{
  width: min-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  label{
    margin-top: -30px;
  }
  img{
    cursor: pointer;
    width: 62.37px;
    height: 18.4px;
  }
}

.radio_margin{
  margin-bottom: 30px;
}

.radio_btn{
  display: block;
  cursor: pointer;
  user-select:none;
  text-align: left;
  input{
    display: none;
    &+span{
      display: inline-block;
      position: relative;
      padding-left: 30px;

      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-transform: capitalize;

      color: #000000;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        display: flex;
        font-size: 0;
        justify-content: center;
        align-items: center;
        width: 20px !important;
        height: 20px !important;
        max-width: 20px;
        min-width: 20px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      &:after{
        position: absolute;
        top: 5.8px !important;
        left: 5.8px !important;
        content: '';
        display: flex;
        width: 10px !important;
        height: 10px !important;
        background: #223D40;

        border-radius: 50%;

        opacity: 0;
        transform: scale(0,0);
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }
    &:checked+span:after{
      opacity: 1;
      transform: scale(1,1);
    }
  }
}



.row_inputs_div{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.tel_input_div{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  padding:0 15px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 5px;

  input {
    border: none !important;
    padding: 15px 15px 15px 2px !important;
  }
}

.tel_span{
  color: #000 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.24px !important;
  line-height: normal !important;

}

.input_div{
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;


  label{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #730101;
  }
  span{
    color: #9B1717;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {

    z-index: 1 !important;
    -webkit-text-fill-color: #000000 !important;
    caret-color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height, or 117% */

    color: #000000 !important;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  input{
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 5px;
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.24px !important;
  }
  textarea{
    outline: none;
    resize: none;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 100% !important;
    height: 100px !important;
    padding: 15px;
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.24px !important;
  }
}

.checkout_btn{
  width: 112px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: #FFFFFF;
  border-radius: 3.33654px;
  padding: 10px;

  font-weight: 700;
  font-size: 10.6769px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #730101;

}


.input_date_div {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  label{
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #663B20;
  }
}
.nav_btn_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  padding: 0 5vw;
}

.total_price_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5vw;
  gap: 30px;

  span {
    box-sizing: border-box;

    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #FFFFFF;


  }
}
.checkout_div{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total_price {
  width: 107.07px;
  height: 36.41px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    padding: 0 20px;
    position: absolute;
    margin-right: -5px;
    font-weight: 700;
    font-size: 14.9708px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
  img{
    width: 107.07px;
    height: 36.41px;
  }
}

.delivery_price{
  display: flex;
  justify-content: center;
  align-items: center;



  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.delivery_icon{
  width: 122px;
}


