:global{
  .css-12t0dn4-MuiClockPointer-thumb, .css-eg3pzz-MuiClockPointer-thumb{
    width: 4px;
    height: 4px;
    background-color: #663B20 !important;
    border-radius: 50%;
    position: absolute;
    top: -21px;
    left: calc(50% - 18px);
    border: 16px solid #663B20 !important;
    box-sizing: content-box;
  }
  .css-d0vs79-MuiClockPointer-root, .css-rdq5h4-MuiClockPointer-root{
      width: 2px;
      background-color: #663B20 !important;
      position: absolute;
      left: calc(50% - 1px);
      bottom: 50%;
      transform-origin: center bottom 0px;
  }
  .css-umzx0k-MuiClock-pin{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #663B20 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: #663B20 !important;
  }
  .css-1aquho2-MuiTabs-indicator{
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #663B20 !important;
  }
  .css-1qs309j-MuiButtonBase-root-MuiPickersDay-root.Mui-selected , .css-7oawqu-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
    color: #fff;
    background-color: #663B20 !important;
    font-weight: 500;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #663B20 !important;
  }
}