.main_fixed_div{
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 90%;
  z-index: 1111 !important;
}

.notification_bar{
  width: 310px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  background: #730101;
  border-radius: 5px;


  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: #FFFFFF;
}

.warning{
  background: #730101 !important;
}

.success{
  background: #20DB52 !important;
}