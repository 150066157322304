.section_heading {
  width: 100%;
  margin-bottom: 75px;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }

  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 48px;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }

  hr {
    margin: 0;
    height: 1px;
    width: 138px;
    background: #FFFFFF;
  }
}

.section_heading_mobile {
  margin-bottom: 75px;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }

  h2 {
    margin: 0;
    width: fit-content;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

  }

  hr {
    margin: 0;
    height: 1px;
    width: 42px;
    background: #FFFFFF;
  }
}

.main_section {
  margin-top: 120px;
}

.main_section_mobile{
  margin-top: 75px;
}