
.animal {
  path {
    transition-duration: 0.7s;
  }

  path:not(.beef_tail):hover {
    transform: scale(1.018);
    transform-origin: 50% 50%;
    transition-duration: 0.7s;
    cursor: pointer;
    fill: #730101;
  }
  .beef_tail:hover {
    transition-duration: 0.7s;
    cursor: pointer;
    fill: #730101;
  }

}

.pointer_event_none {
  pointer-events: none;
}

