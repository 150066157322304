.main_favorite_section{
    margin-top: 145px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    padding: 80px 7vw 120px 0;
    background-image: url("../../../../assets/backgronds/favorite/BG.png");
    background-position: center;
    background-size: 100vw;
    background-repeat: no-repeat;
}

.first_section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section_heading {
    margin-bottom: 75px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }

    h2 {
        margin: 0;
        font-weight: 800;
        font-size:  6vw;
        line-height: 117px;
        /* identical to box height */

        text-transform: uppercase;

        color: #FFFFFF;
        z-index: 1;
    }

    hr {

        height: 1px;
        width: 138px;
        background: #FFFFFF;
        position: relative;
        left: -10px;
    }
}


.favorite_products_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.product_background_div{
    width: 225px;
    height: 327px;
    position: absolute;
    z-index: -1;
    background: linear-gradient(162.61deg, rgba(255, 255, 255, 0.18) -5.24%, rgba(255, 255, 255, 0.12) 113.47%);
    /* product background blur */

    filter: blur(5px);
    border-radius: 5px;
}
.product_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 225px;
    max-width: 225px;
    height: 327px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: visible;
    margin-top: 51px;
}

.product_img_div{
    width: 225px;
    height: 225px;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    filter:drop-shadow(0px 0px 20px #000000);
    z-index: 1;
    margin-top: -51px;
    overflow: visible !important;
}

.favorite_btn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
}
.product_img{
    width: 225px;
    height: 225px;
    border-radius: 50%;
    cursor: pointer;
}

.title_favorite_div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product_description{
    box-sizing: border-box;
    padding: 5px 15px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
}

.product_title{
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF !important;
}


.remove_btn{
    cursor: pointer;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


/////////////////////////////////////////////////////

.sliderDiv{
    border: none !important;
    box-sizing: border-box;
    display: flex;
    min-width: 300px;
    width: 18vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 383.56px;
}
.swiper_nav_btn{
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    padding: 0 !important;
    border: none !important;
    background: none;
}

.swiper_nav_div{
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end;
    align-items: center;
    margin-top: 55px;
    gap: 50px;
}

.swiper{
    width: 100% !important;
}
.swiper_btn_div{
    width: 40%;
    min-width: 470px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.display_none{
    display: none;
}