
:global {

  .swiper-slide {
    height: fit-content;
    text-align: center;
    font-size: 18px;
    border: none !important;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: visible;
  }

  .swiper-slide img {
    display: block;
    object-fit: cover;
  }

}
.sliderDiv{
  border: none !important;
  box-sizing: border-box;
  display: flex;

  min-width: 300px;
  width: 18vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 383.56px;
}

.swiper_nav_btn{
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  padding: 0 !important;
  border: none !important;
  background: none;
}

.swiper_btn_div{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.product_section{
  padding: 0 7vw 0 7vw;
  border: none;
  margin-top: 60px;
}
.product_section_mobile{
  padding: 0 0 0 20px;
  border: none;
  margin-top: 60px;
}

.heading_btn_div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}



.display_none{
  display: none;
}

.swiper_nav_div{
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  gap: 41px;
}