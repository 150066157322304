
:global {
  .swiper-pagination-bullet {
    width: 14px !important;
    height: 14px !important;
    border: 3px solid #B17754;
    background-color: #FFFFFF !important;
  }

  .swiper-pagination-bullet-active {
    opacity: 100%;
    border: 3px solid #B17754;
    background-color: #B17754 !important;
  }

}

.mySwiper{
  margin: 0;
  padding: 0;
}

.part_choosing_section {
  width: 100%;
  border: none;
  margin-bottom: 20px;
}

.section_heading{
  margin-top: -50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  h1 {
    margin: 0;
    padding: 0;
    color: #FFF;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.165px;
    z-index: 1;
    white-space: break-spaces;
    text-align: start;
  }
  hr{

    height: 1px;
    width: 25px;
    background: #FFFFFF;
    position: relative;
  }
}

.animal_section{
  width: 100%;
  margin-top: 25px;
  box-sizing: border-box;
  padding: 0 5vw;
  h2{
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
  }

}

.swiper-slide {
  border: none;
  height: fit-content;
  text-align: center;
  font-size: 18px;
  background-color: #f8f9fa !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.display_none {
  display: none !important;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.animal_div{
  width: 100%;
}
.swiper_pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.sliderDiv {
  border: none !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  padding: 0;
  gap: 35px;
}

.swiper_nav_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  border-radius: 10px;

}

.swiper_btn_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
}

.animal {
  width: 100%;
  height: min-content;

  path {
    transition-duration: 0.7s;
  }

  path:not(.beef_tail):hover {
    transform: scale(1.018);
    transform-origin: 50% 50%;
    transition-duration: 0.7s;
    cursor: pointer;
    fill: #730101;
  }


}

.part_choosing_text{
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #730101;

}

.pointer_event_none {
  pointer-events: none;
}

.slide_second_section {
  width: 100%;
}

.product_div {
  width: fit-content;
}
.product_section{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 60px;
}

.paragraph_div {
  white-space: pre-line;

  text-align: left;
  width: 100%;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;

  color: #FFFFFF;

  h1{
    margin: 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}


.beefParagraph {
  font-size: 12px;
}