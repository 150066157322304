.main_section{
  height: min-content;
  margin-top: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap:20px;
}

.main_section_mobile{
  height: min-content;
  margin-top: 55px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 45px;
}

.about_us_section{
  margin-top: 75px;
  height: 100%;
  background-image: url("../../../../assets/backgronds/home/aboutUs/barbecue.webp");
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  img{
    max-width: 907px;
    width: 100%;
    height: 110%;
    position: relative;
    z-index: 1;
    right: 0;
    top: -30px;
  }
}
.about_us_img_mobile{
  width: 100%;
  height: 362px;
  background-image: url("../../../../assets/backgronds/home/aboutUs/aboutUsImg.webp");
  background-repeat: no-repeat;
  background-size: 100% 362px;
  }

.about_us_img_desktop{
  height: 772px;
  width: 774px;
  background-image: url("../../../../assets/backgronds/home/aboutUs/aboutUsImg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.about_us_section_mobile{
  height: 100%;
  background: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  box-sizing: border-box;
  padding: 0 20px;
  img{
    width: 100%;
    position: relative;
    z-index: 1;
    right: 0;
  }
}
.paragraph_div_mobile{
  width: 100%;
  padding:5vw 0 5vw 5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  align-items: center;
  p{
    white-space: pre-line;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #E8E8E8;

    margin: 0;
    padding: 0;

  }
  button{
    cursor: pointer;
    background: #730101;
    border: none;
    border-radius: 5px;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

    padding: 14px 7.6px;
  }
}
.paragraph_div{
  width: 45%;
  padding:5vw 0 5vw 5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  max-width: 500px;
  p{
    white-space: pre-line;
    font-weight: 500;
    color: #E8E8E8;
    font-size: calc(10px + 0.6vw);
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 0;
    padding: 0;

  }
  button{
    cursor: pointer;
    background: #730101;
    border: none;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-transform: uppercase;

    color: #FFFFFF;

    padding: 13px 18px;


  }
}


.section_heading{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }
  hr{

    height: 1px;
    width: 138px;
    background: #FFFFFF;
  }
}

.section_heading_mobile{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }
  hr{

    height: 1px;
    width: 42px;
    background: #FFFFFF;
  }
}

.read_btn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 88px;
    height: 27px;
    box-sizing: border-box;
    cursor: pointer;
    background: #730101;
    border: none;
    border-radius: 3px;
    font-weight: 700;
    font-size: 9px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    margin-right: 30px;
    color: #FFFFFF;

    padding: 7.6px 14px ;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}