.sidebar {
  z-index: 20;
  position: absolute;
  box-sizing: border-box;
  flex: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  background-image: url("../../../../../assets/backgronds/catalog/mobileSideBarBg.png");
  background-size: 100vw;
  background-repeat: no-repeat;
  transition: all 0.5s;
  font-size: 18px;
  font-style: normal;
  transform-origin: right;
  transform: scaleX(0);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 23px;


  padding: 37px 25px 100px;
}


.sidebar_open {
  z-index: 20;
  position: absolute;
  box-sizing: border-box;
  flex: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  background-image: url("../../../../../assets/backgronds/catalog/mobileSideBarBg.png");
  background-size: 100vw;
  background-repeat: no-repeat;
  transition: all 0.5s;
  font-size: 18px;
  font-style: normal;
  transform-origin: right;
  transform: scaleX(1);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 23px;


  padding: 37px 25px 100px;
}
.sidebar_navMenu{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;


}

.sidebar_heading{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  span{
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
    /* identical to box height, or 48px */

    text-transform: uppercase;

    color: #FFFFFF;
  }
}
.clear_filters_btn{
  border: 0.795919px solid #FFFFFF;

  height: 40px;

  border-radius: 3.9796px;
  background: none;

  font-weight: 500;
  font-size: 12.7347px;
  line-height: 98.9%;
  /* identical to box height, or 13px */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
  padding: 14px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  img{
    width: 10px;
  }
}

.clear_filters_btn:hover{
  background: #730101;
  border: none;
}
.close_sidebar_btn_div{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
}

.close_sidebar_btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none
}


.sidebar_inner_div{
  box-sizing: border-box;
  width: 100%;
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  overflow-y: scroll !important;
  min-height: 150px;
}
.last_dropdown{
  width: 100%;
  margin-bottom: 200px !important;
}
