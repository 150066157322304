.main_section_raw {
  margin-top: 150px;
}
@media screen and (max-height: 760px) {
  .main_section_raw {
    margin-top: 200px;
  }
}
.main_section_cooked {
  margin-top: 120px;
}
.cooked_section_heading {
  margin-bottom: 75px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}
.cooked_section_heading_mobile{
  margin-bottom: 75px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

    z-index: 1;
  }

  hr {

    height: 1px;
    width: 42px;
    background: #FFFFFF;
    position: relative;
  }
}
.raw_section_heading {
  width: 100%;
  margin-bottom: 75px;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  div {
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }

  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 48px;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }

  hr {
    margin: 0;
    height: 1px;
    width: 138px;
    background: #FFFFFF;
  }
}

.raw_section_heading_mobile {
  margin-bottom: 75px;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  div {
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }

  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }

  hr {
    margin: 0;
    height: 1px;
    width: 42px;
    background: #FFFFFF;
  }
}



.recently_similar_font{
  h2{
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 39px !important;
    /* identical to box height */

    letter-spacing: 0.01em !important;

    color: #FFFFFF !important;
  }
}