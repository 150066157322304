:global {
  .swiper-grid > .swiper-wrapper {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

}

.main_section {
  margin-top: 145px;
  padding: 100px 0 120px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 75px;
}

.main_section_mobile {
  padding: 150px 0 60px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 75px;
}

.section_heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}

.slider_div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.swiper {
  width: 80%;
}

.swiper_slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper_nav_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  border-radius: 10px;

}

.swiper_btn_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
}

.order_div {
  padding: 35px 35px 0;
  box-sizing: border-box;
  width: 490px;
  height: 256px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;

  gap: 13px;
}

.order_number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;


  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;
}

.date_span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;
}

.order_info_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price_div {
  position: relative;
  right: -35px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    position: absolute;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
;
}

.product_img_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}

.btn_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    cursor: pointer;
    padding: 9px 20px;
    background: none;

    font-weight: 700;
    font-size: 9.56903px;
    line-height: 12px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #730101;


    border: 1px solid #730101;
    border-radius: 2.99032px;
  }

  button:hover {
    color: #FFFFFF;
    background: #730101;
  }
}


/////////mobile//////////////////////


.section_heading_mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 35px;
    line-height: 49px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
    text-align: center;

  }

  hr {
    margin: 0;
    padding: 0;
    height: 1px;
    width: 63px;
    background: #FFFFFF;
    position: relative;
  }
}

.swiper_mobile {
  width: 100%;
  padding: 0 5vw;
  box-sizing: border-box;
}

.order_div_mobile {
  padding: 20px 23px;

  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
}

.order_number_mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;


  font-weight: 600;
  font-size: 15.831px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;;
}

.price_div_mobile {
  position: relative;
  right: -23px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    position: absolute;

    font-weight: 700;
    font-size: 15.831px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }

  img {
    width: 109.87px;
    height: 38.51px;
  }
}

.product_img_div_mobile {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;


  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }

}

.btn_div_mobile {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    cursor: pointer;
    padding: 9px 20px;
    background: none;

    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
    border: none;
    background: #730101;
    border-radius: 2.36699px;
  }
}