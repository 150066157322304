.product_background_div{
    width: 225px;
    height: 327px;
    position: absolute;
    z-index: -1;
    background: linear-gradient(162.61deg, rgba(255, 255, 255, 0.18) -5.24%, rgba(255, 255, 255, 0.12) 113.47%);
    /* product background blur */

    filter: blur(5px);
    border-radius: 5px;
}
.product_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 225px;
    max-width: 225px;
    height: 327px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: visible;
    margin-top: 51px;
}

.product_img_div{
    width: 225px;
    height: 225px;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    filter:drop-shadow(0px 0px 20px #000000);
    z-index: 1;
    margin-top: -51px;
    overflow: visible !important;
}

.favorite_btn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
}
.product_img{
    width: 225px;
    height: 225px;
    border-radius: 50%;
    cursor: pointer;
}

.title_favorite_div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product_description{
    box-sizing: border-box;
    padding: 5px 15px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
}

.product_title{
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF !important;
}
