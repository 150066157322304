.tool_bar_div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.first_tools{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.product_state_choose{
  box-sizing: border-box;
  display: flex;
  flex-direction: row ;
  justify-content: space-between;
  gap: 30px;
}

.product_state_inactive {
  background: none;
  box-sizing: border-box;
  padding: 7px 0 7px 0;
  width: 180px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1vw;
  justify-content: center;
  align-items: center;
  transition-property: padding, background-color, color;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;


  }
}

.product_state_active {
  box-sizing: border-box;
  padding: 7px 0 7px 0;
  width: 180px;
  transition-duration: 1s;

  background: #FFFFFF;
  border-radius: 5px;


  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: center;
  align-items: center;
  transition-property: padding, background-color, color;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #730101;

  }
}


.drop_down_btn{
  height: 42px;
  width: calc(10px + 18vw);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(177, 119, 84, 0.16);
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  border: none;

  span{

    font-weight: 700;
    font-size: calc(10px + 0.45vw);
    line-height: 19px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #663B20;
  };
}

.sort_dropdown{
  margin-top: 15px;
  box-sizing: border-box;
  width: calc(10px + 18vw);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;
  background: #F0F0F0;
  padding: 1px;
  border-radius: 10px;
}

.dropdown_div{
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  background: #FFFFFF;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.first_sort_div{
  border-radius: 10px 10px 0 0;
}

.last_sort_div{
  border-radius: 0 0 10px 10px;
}

// mobile version ////////////////////////////////

.tool_bar_div_mobile{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.product_state_choose_mobile{
  box-sizing: border-box;
  display: flex;
  flex-direction: row ;
  justify-content: space-between;
  gap: 30px;
}

.product_state_inactive_mobile {
  background: none;
  box-sizing: border-box;
  padding: 7px 16px;
  min-width: 96px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1vw;
  justify-content: center;
  align-items: center;
  transition-property: padding, background-color, color;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;


  }
}


.product_state_active_mobile {
  box-sizing: border-box;
  padding: 7px 16px;
  min-width: 96px;
  transition-duration: 1s;

  background: #FFFFFF;
  border-radius: 5px;


  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: center;
  align-items: center;
  transition-property: padding, background-color, color;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #730101;

  }
}