.catalog_section{
  box-sizing: border-box;
  width: 100%;
  gap: 20px;
  padding: 274px 0 75px 0 ;
}
.section_heading_div{
  p{
    margin: 0;
    padding: 20px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
  h1{
    margin-bottom: 20px;
  }
  box-sizing: border-box;

  text-align: center;
  gap: 20px;
}

.centered_flex_div{
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forkBg{
  position: absolute;
  z-index: -1;
  top: 0;
}

.catalogBtn{
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #663B20;
  border-radius: 8px;
  gap: 10px;

  span{
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.main_catalog_div{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 45px;
}

.sidebar_div{
  min-width: 185px;
  width: 20%;
}

.product_div{
  box-sizing: border-box;
  padding: 0 5vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.products_section{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
}