.mySwiper{
  overflow: hidden !important;
  box-sizing: border-box;
  z-index: 4 !important;
}

.display_none{
  display: none;
}
.sliderDiv{
  -webkit-transform: translate3d(0,0,0);
  border: none !important;
  box-sizing: border-box;
  display: flex;

  width: 160px;
  height: 290px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;

  img{
    width: 100%;
    transition: transform 2s;
    z-index: 1 !important;
    transform: scale(1.4);
    transform-origin: 50% 50%;
    z-index: 1 !important;

  }
}

.sliderDiv_inactive{
  -webkit-transform: translate3d(0,0,0);
  border: none !important;
  box-sizing: border-box;
  display: flex;

  width: 160px;
  height: 290px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  img{
    width: 100%;
    transition: transform 2s;
    z-index: 1 !important;
    filter: grayscale(100%);
  }
}

.slider_container{
  overflow: hidden !important;
  box-sizing: border-box;
  position: relative;

}

.category_section_container{
  position: relative;
  height: fit-content;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: calc(30px + 3vw);
}

.category_btn{
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0,0,0);
  position: relative;
  width: 100%;
  height: 100%;
  min-width: fit-content;
  min-height: fit-content;
  padding: 0;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  background: none;


  h3{
    position: absolute;
    z-index: 2;
    margin-left: 210px;
    margin-bottom: 100px;
    -webkit-transform: translate3d(0,0,0);
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}

