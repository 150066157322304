
.calculator_section {
  width: 100%;
  max-width: 468px;
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.calculator_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}

.quantityDiv {
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 5px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-sizing: border-box;
  background: #730101;
  border-radius: 3px;
  border: none;

  span {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;


    font-size: 15px;
    font-weight: bold;
  }
}

.quantity {
  max-width: 63px;
  width: 5vw;
  min-width: 56px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.delivery_icon{
  max-width: 122px;
  width: 10vw;
}
.delivery_price{
  display: flex;
  justify-content: center;
  align-items: center;



  font-weight: 700;
  font-size: calc(10px + 0.5vw);
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}
.price {
  font-weight: 700;
  font-size: calc(12px + 0.6vw);
  line-height: 30px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;

  span {
    color: #730101
  }
}

//.cooked_font {
//  color: #FFFFFF !important;
//}

.remove_item_btn {
  cursor: pointer;
  width: 50%;
  max-width: 140px;
  height: 47px;
  border: 1.23303px solid #FFFFFF;
  background: none;
  backdrop-filter: blur(2.05003px);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 2px;

  span {
    font-weight: 700;
    font-size: 16.5403px;
    line-height: 20px;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.btn_flex_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
}

.add_btn {
  cursor: pointer;
  width: 50%;
  max-width: 140px;
  height: 47px;
  border: 1.23303px solid #FFFFFF;
  background: none;
  backdrop-filter: blur(2.05003px);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 2px;

  span {
    font-weight: 700;
    font-size: 16.5403px;
    line-height: 20px;
    text-transform: capitalize;

    color: #FFFFFF;
  }
  img{
    width: 16px;
    height: 14px;
  }
}

.product_img_div_mobile{
  width: 100%;
  display: flex;
  justify-content: center;

  img{
    width: 184px;
    height: 184px;
    border-radius: 50%;
  }
}


.buy_now_btn {
  cursor: pointer;
  width: 50%;
  max-width: 140px;
  height: 47px;

  background: #730101;
  border: 0.820014px solid rgba(0, 0, 0, 0.08);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 2px;

  span {
    font-weight: 700;
    font-size: 16.5403px;
    line-height: 20px;
    text-transform: capitalize;

    color: #FFFFFF;
  }

  img{
    height: 14px
  }
}


///////////////////////////////////////////////mobile///////////////////////////////
.btn_flex_div_mobile{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.remove_item_btn_mobile{
  cursor: pointer;
  width: 109.68px;
  height: 37.24px;
  border: 1.23303px solid #FFFFFF;
  background: none;
  backdrop-filter: blur(2.05003px);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 2px;

  span {
    font-weight: 700;
    font-size: 10.6137px;
    line-height: 20px;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}
.add_btn_mobile{
  width: 109.68px;
  height: 37.24px;
  border: 1.23303px solid #FFFFFF;
  background: none;
  backdrop-filter: blur(2.05003px);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 2px;

  span {
    font-weight: 700;
    font-size: 10.6137px;
    line-height: 13px;
    /* identical to box height */

    text-transform: capitalize;

    color: #FFFFFF;
  }
  img{
    width: 11px;
    height: 9px;
  }
}

.buy_now_btn_mobile{
  cursor: pointer;
  width: 109.68px;
  height: 37.24px;

  box-sizing: border-box;
  background: #730101;
  border: 0.820014px solid rgba(0, 0, 0, 0.08);
  border-radius: 4.10007px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 2px;

  span {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 10.6137px;
    line-height: 13px;
    /* identical to box height */

    text-transform: capitalize;

    color: #FFFFFF;
  }
}