
:global {
  .swiper-pagination-bullet {
    width: 14px !important;
    height: 14px !important;
    border: 3px solid #B17754;
    background-color: #FFFFFF !important;
  }

  .swiper-pagination-bullet-active {
    opacity: 100%;
    border: 3px solid #B17754;
    background-color: #B17754 !important;
  }

}

.mySwiper{
  margin: 20px 0;
  padding: 0;
}

.part_choosing_section {
  border: none;
  display: flex;
  flex-direction: column;
}

.section_heading{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: calc(10px + 2vw) !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }
  hr{

    height: 1px;
    width: 5vw;
    background: #FFFFFF;
    position: relative;
  }
}

.prompt_text{
  font-size: 24px !important;
  font-weight: 500;
  letter-spacing: 0.24px;
  max-width: 280px;
  text-align: start !important;
}

.animal_section{
  margin-top: 75px;
  box-sizing: border-box;
  padding: 0 7vw 0 7vw;
  h2{
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
  }

}

.swiper-slide {
  border: none;
  height: fit-content;
  text-align: center;
  font-size: 18px;
  background-color: #f8f9fa !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.display_none {
  display: none !important;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}


.swiper_pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.sliderDiv {
  border: none !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  min-width: 400px;
  padding: 0;
}

.swiper_nav_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  border-radius: 10px;

}

.swiper_btn_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
}

.slide_second_section {
  width: 50%;
}
.product_div {
  width: fit-content;
}
.product_section{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 60px;
}

.paragraph_div {
  white-space: pre-line;

  text-align: left;
  width: 100%;

  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: #FFFFFF;

  h1{
    margin: 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
  }
}


.beefParagraph {
  font-size: 12px;
}