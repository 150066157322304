.main_section {
  margin-top: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.review_centered_section {
  margin-top: 75px;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 70px;

  h2 {
    white-space: break-spaces;
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 48px;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
  }

  h3 {

    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.section_heading {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}

.swiper {
  margin-top: 100px;
  box-sizing: border-box;
  width: 100% !important;
  overflow: visible !important;
  overflow-x: hidden;
}

.heading_btn_div {
  padding: 0 35px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.swiper_div {
  margin-top: 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.center_slide {
  position: relative;
  top: -90px;
}

.sliderDiv {
  border: none !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  max-width: 360px;
  width: 24vw;
  margin: 20px 0 70px 0 !important;
  background: none;

  p {
    margin: 35px 0 20px 0;
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    max-width: 360px;
    overflow-wrap: break-word;

    color: #FFFFFF;

  }
}

.username{
  margin-top: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.date{
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;

}
.swiper_nav_btn {
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  padding: 0 !important;
  border: none !important;
  background: none;
  z-index: 4;
}

.swiper_btn_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.comment_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: -170px;
}
