.main_section{
  padding: 115px 0 64px;

  background-image: url("../../../../assets/backgronds/emptyPage/emptyPageBgMobile.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.section_heading{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1{
    margin: 0;
    padding: 0;

    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
    /* or 48px */

    text-align: center;
    text-transform: capitalize;

    color: #FFFFFF;

  }
  hr{
    background: #fff;
    height: 1px;
    margin: 0;
    width: 42px;
  }
}

.flex_column_divs{
  padding: 0 5vw;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paragraph_div{
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  span{
    margin: 0;
    padding: 0;

    white-space: break-spaces;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: center;

    color: #FFFFFF;
  }

  gap: 30px;
}

.back_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;

  background: #730101;
  border-radius: 5px;
  border: none;

  font-weight: 700;
  font-size: 13.3558px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}