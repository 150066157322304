.popupContainer {
  position: fixed;
  z-index: 50 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.popupBody {
  position: absolute;
  z-index: 50 !important;
  min-height: 600px;
  max-height: 640px;
  min-width: 800px;
  max-width: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5vh 3vw 2vh 3vw;
  border-radius: 15px;
  border-color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-image: url("../../../../assets/backgronds/login/loginBackground.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #000000;
  background-position: left;
  overflow: hidden;
}


@media screen and (max-width: 1024px){
  .popupBody {
    min-height: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    max-width: unset !important;
    width: 100%;
    height: 100vh;
    padding: 5vh 3vw 2vh 3vw;
    border-radius: 0;
    border-color: unset;
    background-image: url("../../../../assets/backgronds/login/loginBackgroundMobile.png");
  }

}