.section_heading {
  margin-bottom: 55px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  div{
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }
  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
  hr{
    margin: 0;
    height: 1px;
    width: 138px;
    background: #FFFFFF;
  }
}
.section_question{
  margin-bottom: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;

}

.section_question_mobile{
  margin-bottom: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.question_section{
  width: 100%;
  display: flex;
  gap:50px;
  justify-content: space-between;
  padding: 0 7vw;
  box-sizing: border-box;

}

.question_section_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
  box-sizing: border-box;
}

.question_section_two_divs{
  display: flex;
  flex-direction: column;
  gap: 250px;
}

.second_question_div{
  margin-top: 160px;
}