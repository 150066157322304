.main_section{
  margin-top: 145px;
  box-sizing: border-box;
  padding: 50px 0 90px;
}

.section_heading{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h1 {
    margin: 0;
    padding: 0;
    color: #FFF;
    font-size: 48px;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.96px;
  }
  hr{

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}

.centered_div{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 7vw;

  img{
    margin-top: 20px;
  }
}

.paragraph{
  padding: 0;
  margin:0 ;
  white-space: break-spaces;
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.24px;
}

.btns_div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34px;
  margin-top: 30px;
}

.menu_btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215.814px;
  height: 51.934px;
  border-radius: 5px;
  background: #730101;

  border: none;

  color: #FFF;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}

.contact_us_btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 216px;
  height: 52px;

  border-radius: 5px;
  background: #FFF;

  border: none;

  color: #730101;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}

/////mobile////////////////////////////


.main_section_mobile{
  box-sizing: border-box;
  margin-top: 125px;
  padding: 50px 0 90px;
}

.section_heading_mobile{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  h1 {
    margin: 0;
    padding: 0;
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.56px;
  }
  hr{

    height: 1px;
    width: 32px;
    background: #FFFFFF;
    position: relative;
  }
}

.centered_div_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 7vw;

  img{
    margin-top: 20px;
  }
}

.paragraph_mobile{
  padding: 0;
  margin:0 ;
  white-space: break-spaces;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 134.188%;
  letter-spacing: 0.18px;
}

.btns_div_mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.menu_btn_mobile{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 113px;
  height: 41.7px;
  border-radius: 5px;
  background: #730101;

  border: none;

  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
  text-transform: uppercase;

}

.contact_us_btn_mobile{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
  padding: 0 20px;
  min-width: 113px;
  height: 41.7px;

  border-radius: 5px;
  background: #FFF;

  border: none;

  color: #730101;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
  text-transform: uppercase;
}