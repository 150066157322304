.footer {
  user-select: none;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  background: none;
  padding: 16px 7vw 16px 7vw ;
  border-top: 1px solid #FFFFFF;;

  gap: 12.5px;
}

.footer_first_section{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.footer_logo_section{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.footer_section{
  height: 72px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  a{
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.footer_social_section{
  align-items: flex-start;
  gap:20px
}

.footer_second_section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-weight: 300;
  font-size: 6px;
  line-height: 7px;

  color: #FFFFFF;


}


.contact_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: rgba(255, 255, 255, 0.8);

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }
  img{
    width: 12px;
  }
  p{
    margin: 0;
    padding: 0;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.section_heading{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.section_btn{
  cursor: pointer;
  background: none;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}