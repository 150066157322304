.input_field_with_button{
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.input_section{
  width: 48vw;
  max-width: 590px;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border-radius: 5px;
  padding: 10px 20px 10px ;
  gap: 10px;
  z-index: 0;
}

.blur_background{
  width: 48vw;
  max-width: 590px;
  height: 80px;
  position: absolute;
  margin-left: -20px;
  background: linear-gradient(338.24deg, rgba(255, 255, 255, 0.8) -1.32%, #FFFFFF -1.31%, #FFFFFF 109.86%);
  /* product background blur */

  filter: blur(2.5px);
}

.input{
  z-index: 1;
  background: none;
  margin: 0;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 140.2%;
  /* or 22px */

  letter-spacing: 0.01em;

  color: #757575;

}

.send_btn{
  padding: 13px 38px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: capitalize;

  color: #FFFFFF;


}


@media only screen and (min-width: 0px) and  (max-width: 871px){
  .input_field_with_button{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .input_section{
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    padding: 10px 20px 10px ;
    background: linear-gradient(338.24deg, rgba(255, 255, 255, 0.8) -1.32%, #FFFFFF -1.31%, #FFFFFF 109.86%);
    gap: 10px;
    z-index: 0;
  }

  .blur_background{
    width: 100%;
    height: 48px;
    position: absolute;
    margin-left: -20px;
    background: linear-gradient(338.24deg, rgba(255, 255, 255, 0.8) -1.32%, #FFFFFF -1.31%, #FFFFFF 109.86%);

    /* product background blur */
    filter: blur(2.5px);
    display: none;
  }

  .input{
    z-index: 1;
    background: none;
    margin: 0;
    border: none;
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 10.9817px;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #000000;
  }
  .send_btn{
    padding: 7.5px 24.26px;
    cursor: pointer;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;

    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
  :global{
    .MuiRating-iconEmpty, .MuiRating-iconFilled{
      font-size: 15px !important;
    }
  }

}