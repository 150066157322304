.signin_section {
  box-sizing: border-box;
  padding: 0 7vw;
  width: 100%;
  height: 100vh;
  gap: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-image: url("../../../assets/backgronds/login/loginBackground.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.heading_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.back_btn {
  position: absolute;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  background: none;
  border: none;
  margin-left: 7vw;

  span {
    height: min-content;
    font-weight: 500;
    font-size: 16px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
}

.heading {
  font-weight: 800;
  font-size: 48px;
  line-height: 28px;
  /* or 58% */

  display: flex;
  align-items: center;

  color: #FFFFFF;

}

.login_section {
  max-width: 457px;
  min-width: 328px;
  width: 35vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.input_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 7px;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.login_popup_btn {
  cursor: pointer;
  border: none;
  background: #730101;
  border-radius: 5px;

  box-sizing: border-box;

  padding: 9px 26px;

  font-weight: 600;
  font-size: 16px;
  line-height: 25px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;
}


.back_btn_div_mobile {
  margin-top: 50px;
  width: 100%;
}


@media screen and (max-width: 1024px) {
  .signin_section {
    padding: 0 5vw;
    gap: 20px;
    background-image: url("../../../assets/backgronds/login/loginBackgroundMobile.png");
  }

  .back_btn {
    position: relative !important;
    float: left;
    margin: 0 !important;
  }

  .login_section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 250px;
  }
}