.main_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5vw 0 0;
}

.special_offers {
  user-select: none;
  height: 34px;
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  span {
    margin-left: 12px;
    position: absolute;
    font-weight: 500;
    font-size: 11.1835px;
    line-height: 98.9%;
    /* identical to box height, or 11px */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #730101;
  }

  img {
    height: 34px;
    width: 134px;
  }
}

.enable_font_color {
  span {
    color: #FFFFFF !important;
  }
}

.catalogBtn {
  border: none;
  background: none;
  img{
    width: 34.83px;
    height: 31.042px;
  }
}