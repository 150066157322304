.collect_section {
  margin-bottom: 150px;
}

.aboutUs_main_section {
  margin-top: 245px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}


.section_heading {
  margin-bottom: 55px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  div {
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }

  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }

  hr {
    margin: 0;
    height: 1px;
    width: 138px;
    background: #FFFFFF;
  }
}

.faq_section_heading {
  margin: 120px 0 75px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}


.second_section {
  display: flex;
  flex-direction: column;
}

.about_img {
  max-width: 564px;
  width: 39vw;
}


.chili_img {
  position: absolute;
  margin-bottom: -130px;
  margin-right: -83px;
}

.paragraph_div {
  margin-right: 5vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 617px;
  height: 406px;
  overflow: hidden;
  background-image: url("../../../../assets/backgronds/aboutUs/board.png") ;
  background-size: 100% 100%;

  p {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 25px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;

    white-space: break-spaces;

    color: #FFFFFF;

  }
}