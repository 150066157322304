.popup_container {
  z-index: 10 !important;
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}


.popup_body {
  z-index: 2 !important;
  overflow-y: auto;
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: 20%;
  top: 20%;
  min-height: 425px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 5vh 3vw 2vh 3vw;
  border-radius: 15px;
  border-color: black;
  background: #730101;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.popup_body_mobile{
  z-index: 2 !important;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  box-sizing: border-box;
  padding: 36px 24px;
  border-color: black;
  background: #730101;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.close_btn_div{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button{
    cursor: pointer;
    background: none;
    border: none;
  }
}

.mobile_product_section{
  box-sizing: border-box;
  padding: 0 25px;
}