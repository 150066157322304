.main_section_div{
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-image: url("../../../../assets/backgronds/product/BG.webp");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 145px;
    padding: 0 0 120px 0;
    overflow: hidden;
}


.nav_div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5vw;
}
.navigate_btn{
    cursor: pointer;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background: none;
    border: none;
    span{
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: #FFFFFF;
    };
}

.product_preview_div{
    z-index: 1;
    padding: 0 7vw 0 7vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    box-sizing: border-box;
}


.heading_section{
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.favorite_btn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
}

.heading_second_section{
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 15px;
}

.img_section_div{
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 37vw;
    height: 37vw;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover; 
    }
}


.second_section_div{
    max-width: 468px;
    z-index: 1;
    position: relative;
    width:45%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.product_title{
    z-index: 1;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    /* identical to box height, or 34px */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #FFFFFF;
}
  
.product_type{
    z-index: 1;
    background: #B17754;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 5px 10px 5px 10px ;
    width: fit-content;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    color: #FFFFFF;
}

.delivery_btn{
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background: none;

    border: none;

    gap: 10px;

    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #201E1E;
}


/////////////////////mobile//////////////////////////////

.main_section_div_mobile{
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 150px 0 40px;
    overflow: hidden !important;
}

.product_preview_div_mobile{
    z-index: 1;
    padding: 0 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    box-sizing: border-box !important;
}

.second_section_div_mobile{
    z-index: 1;
    position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    overflow: hidden;
}

.heading_section_mobile{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
}

.product_title_mobile{
    display: flex;

    z-index: 1;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

}

.favorite_btn_mobile{
    background: none;
    border: none;

    img {
        width: 29.79px;
        height: 26.02px;
    }
}