.sidebar {
  z-index: 20;
  position: absolute;
  box-sizing: border-box;
  flex: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  transform-origin: right;
  transform: scaleX(0);
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 65px;
}


.sidebar_open {
  z-index: 20 !important;
  position: absolute;
  box-sizing: border-box;
  padding: 0 0 70px 0;
  flex: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
  transition: all 0.5s;
  font-size: 18px;
  font-style: normal;
  transform-origin: right;
  transform: scaleX(1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 65px;
  overflow-y: auto;
}

.close_sidebar_btn_div{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding: 57px 25px 0;
}

.close_sidebar_btn{
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
.specialOffersImg{
  width: 176px !important;
  height: 70px;
  position: absolute;
  z-index: 0;
  left: 0;
  background-image: url("../../../../../assets/icons/homePage/commonIcons/specialOffers.png");
  background-size: 176px 70px;
  background-repeat: no-repeat;
}
.special_offers_btn {
  width: 176px !important;
  height: 70px;
  max-height: 70px;
  max-width: 176px !important;
  background: none;
  box-sizing: border-box;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  user-select: none;

  span {
    position: relative;
    left: -10px;
    width: 176px !important;
    height: 70px;
    overflow-wrap: break-word;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    padding-left: 20px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 20px;
    line-height: 98.9%;
    /* or 20px */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;


  }
}


.sidebar_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 23px;


  a{
    text-decoration: none;
    appearance: none;
    font-weight: 500;
    font-size: 32.9625px;
    line-height: 40px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #000000;
    user-select: none;
  }
}

.row_div{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  padding: 0 25px 0 0;
}

.lang_btn {
  width: 73px;
  box-sizing: border-box;
  padding: 7.5px;
  border: none;
  background: #000000;
  border-radius: 3px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;


  }

}

.lang_dropdown_menu {
  margin-top: 7px;
  width: 73px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background: none;
  border: none;
  gap: 6px;
  button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #000000;

  }
}