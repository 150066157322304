.main_section {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 45px;
  margin-top: 105px;
  padding: 45px 0  45px;
  background-image: url("../../../../assets/backgronds/cart/cartMobileBg.webp");
  background-size: 100vw;
}

.section_heading{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  div{
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }
  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

  }
  hr{
    margin: 0;
    height: 1px;
    width: 42px;
    background: #FFFFFF;
  }
}


.first_section {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.item_heading_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.cart_product_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5vw 0 5vw;
}

.cart_item_row_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.product_div {
  width: 100%;
  margin-right: 13px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  background: #F3F3F3;
  border: 2px solid rgba(32, 30, 30, 0.05);
  border-radius: 12px;
  padding: 13px;
  max-width: 320px;
  min-width: 250px;
}

.empty_cart_div {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

}

.product_img_div {
  width: max-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 64px;
    height: 64px;
  }
}

.empty_cart_text {
  font-weight: 800;
  font-size: 25px;
  font-family: "Lato";
  font-style: normal;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #201E1E;
}

.back_shop_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ECECEC;

  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 15px calc(15px + 2vw) 15px calc(15px + 2vw);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #201E1E;

}

.cart_item_price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;
}

.cart_item_heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 100.9%;
  /* or 12px */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #000000;
}

.remove_item_btn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  cursor: pointer;

  width: 36px;
  height: 38px;
  background: #730101;
  border-radius: 5.17204px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.checkout_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
}

.check_out_btn {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 19%;
  min-width: 290px;
  max-width: 320px;

  background: #663B20;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  padding: 15px 0 15px 0;

  color: #FFFFFF;
}

.total_price_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5vw;

  span {
    box-sizing: border-box;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;

  }
}

.total_price {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    padding: 0 20px;
    position: absolute;

  }
}

.nav_btn_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  padding: 0 5vw;
}

.back_shop_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;

  background: #FFFFFF;
  border-radius: 5px;
  padding: 16px 20px;


  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #730101;
}

.checkout_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #730101;
  border-radius: 5px;
  border: none;
  padding: 16px 20px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}



