
.customInput:-webkit-autofill,
.customInput:autofill,
.customInput:-webkit-autofill:hover,
.customInput:-webkit-autofill:focus,
.email_input:-webkit-autofill,
.email_input:-webkit-autofill:hover,
.email_input:-webkit-autofill:focus {
  width: 100% !important;
  appearance: none !important;
  z-index: 1 !important;
  -webkit-text-fill-color: #FFFFFF !important;
  caret-color: white !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  /* identical to box height, or 117% */

  color: #FFFFFF !important;
  -webkit-box-shadow: none !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background: none !important;
  background-color: inherit !important;
  box-sizing: border-box !important;
}




.email_input {
  height: 100%;
  width: 100%;
  text-transform: lowercase;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  color: #FFFFFF;


  padding: 0 10px 0 10px;
  /* identical to box height */
  margin: 0;
  letter-spacing: 0.02em;
  font-size: 18px;
  background: none;
  border: none;
  border-radius: 5px;
}

.email_input:focus {
  outline: none !important;
}

.customInput::-webkit-outer-spin-button,
.customInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.customInput[type=number] {
  -moz-appearance: textfield;
}

.customInput {
  box-sizing: border-box;

  padding: 0 10px 0 10px;
  margin: 0;
  z-index: 1 !important;
  position: relative;
  background: none;
  border: none;
  width: 100%;
  height: 100%;


  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  color: #FFFFFF;

}

.tel_span {
  pointer-events: none;
  font-size: 18px;
  margin-left: 10px;
  margin-right: -5px;
}

.customInputDiv {
  width: 100%;
  height: min-content;
  z-index: 1 !important;
  display: flex;
  flex-direction: row;
  align-items: center;


  box-sizing: border-box;

  background: none;
  border: 1px solid #FFFFFF;
  border-radius: 5px;

  span {
    cursor: pointer;
  }
;
}

.customInput:focus {
  outline: none !important;
}
