.mobile_navbar_home {
  height: 109px;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px;
  z-index: 3;
  transition-duration: 700ms;
}

.mobile_navbar_home_scrolled {
  height: 60px;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 20px 0;
  z-index: 3;
  transition-duration: 700ms;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(2px);
}
.navbar_logo{
  width: 173px;
  height: 15px;
  background-image: url("../../../../assets/logo/mobileNavbar.png");
  background-repeat: no-repeat;
  background-size: 173px 15px;
  transition-duration: 700ms;
}
.navbar_logo_scrolled{
  width: 120px;
  height: 12px;
  background-image: url("../../../../assets/logo/mobileNavbar.png");
  background-repeat: no-repeat;
  background-size: 120px 12px;
  transition-duration: 700ms;
}

.nav_section_first_div {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
}
.contact_section {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #E8E8E8;
  user-select: none;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  img{
    width: 15px;
    transition-duration: 700ms;
  }
  p{
    margin: 12px 0;
    font-weight: 400;
    font-size: 12px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

    transition-duration: 700ms;
  }
}
.contact_section_scrolled {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: rgba(255, 255, 255, 0.8);
  user-select: none;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  img{
    width: 12px;
    transition-duration: 700ms;
  }

  p{
    margin: 0 0;
    font-weight: 400;
    font-size: 12px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    transition-duration: 700ms;
    color: #FFFFFF;
  }
}

.menuBtn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.login_btn {
  cursor: pointer;
  border: none;

  background: #730101;
  border-radius: 3px;

  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;

  padding: 8px 17px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  height: 27px;
}

.profile_btn {
  padding: 0 ;
  height: 36px;
  width: 36px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background: none;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


}

.navbar_second_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 23px;
}

.dropdown_menu {
  width: 170px;
  box-sizing: border-box;
  z-index: 4;
  margin-top: 14px;
  position: absolute;
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;

  gap: 10px;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border: none;
    background: none;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      text-transform: capitalize !important;
      letter-spacing: 0.01em;

      color: #FFFFFF;

    }
  }

}