.main_section{
  padding: 200px 5vw 95px;

  background-image: url("../../../../assets/backgronds/emptyPage/emptyPageBg.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.section_heading{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1{
    margin: 0;
    padding: 0;

    font-weight: 800;
    font-size: 48px;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
  }
}

.flex_row_divs{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.paragraph_div{
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.01em;

    color: #FFFFFF;
  }

  gap: 30px;
}

.back_btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;

  background: #730101;
  border-radius: 5px;
  border: none;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}