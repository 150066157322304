.display_none{
  display: none;
}

.calculator_section_desktop{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 0;
}


.calculator_div{
  width: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}

.quantityDiv{
  background: #DDE0E0;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  min-height: 50px;
  min-width: 110px;
  width: 8vw;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  gap: 5px;
  padding:  0 10px 0 10px ;
}

.quantity_div{
  background: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  min-width: 135px;
  max-width: 160px;
  width: 32vw;

  gap: 5px;
}

.btn{
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-sizing: border-box;
  color: #FFFFFF;
  background: #663B20;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  font-size: 18px;
  font-weight: bold;
}

.calculator_btn{
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: #730101;
  border-radius: 7.89576px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity{
  font-weight: 500;
  font-size: calc(14px + 0.7vw);
  line-height: 29px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;
}
@media screen and (min-width: 1600px){
  .quantity {
    font-size: 25px;
  }
}
.price{
  width: 15vw;

  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}



.remove_item_btn_div{
  width:8vw;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.remove_item_btn{
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
  box-sizing: border-box;
  cursor: pointer;

  width: 36px;
  height: 38px;
  background: #730101;
  border-radius: 5.17204px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

//////////////////////////////////////////////////////////
.calculator_section_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
}

.calculator_btn_mobile{
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: #730101;
  border-radius: 5px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 14.7px;
  }
}

.quantity_mobile{
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;
}

.quantity_div_mobile{
  background: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  min-width: 110px;
  max-width: 160px;
  width: 20vw;

  gap: 5px;
}

.price_mobile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;

}