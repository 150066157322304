.section_div{
  width: 100%;
}

.item_heading_div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.checkout_products_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.product_div{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: -1.14433px 1.14433px 4.57732px rgba(255, 255, 255, 0.25);
  border-radius: 5.72165px;
  padding: 15px 28px;

}



.product_img{
  width: 75px;
  height: 75px;
}

.cart_item_price{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 153px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;

}

.cart_item_heading{
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-weight: 600;
  font-size: 13px;
  line-height: 100.9%;
  /* or 13px */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #000000;
}

.total_price{
  width: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #000000;
}

.back_shop_btn{
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ECECEC;

  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 15px calc(15px + 2vw) 15px calc(15px + 2vw);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #201E1E;

  margin-bottom: 30px;
}