.main_section_div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.section_paragraph{
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #FFF;
  font-size: calc(10px + 1.7vw);
  font-weight: 600;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  span{
    color: #730101;
    font-size: calc(10px + 3.4vw);
    font-weight: 800;
    letter-spacing: 0.64px;
  }
}

.section_img{
  width: 30vw;
  max-width: 383px;
}

//////mobile/////////////////

.main_section_div_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.section_paragraph_mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #FFF;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.26px;
  white-space: nowrap;
  flex-wrap: wrap;
  span{
    color: #730101;
    font-size: 40px;
    font-weight: 800;
    line-height: 120%;
  }
}

.section_img_mobile{
  width: 280px;
}