.catalog_section {
  background-image: url("../../../../assets/backgronds/catalog/menuBg.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 280px 0 120px;
}

.centered_flex_div {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fork_desktop {
  position: absolute;
  z-index: 1;
  top: 0;
}

.section_heading_div {
  width: 100%;
  color: #FFFFFF;

  h2 {
    width: 100%;
    text-align: center;
    margin: 0 0 20px;
    padding: 0;

    font-weight: 800;
    font-size: 48px;
    line-height: 120%;
    /* identical to box height, or 58px */

    text-transform: capitalize;

    color: #FFFFFF;
  }
}

.main_catalog_div {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 0 7vw;
}

.sidebar_div {
  min-width: 185px;
  width: 20%;
}

.tool_bar_div {
  width: 100%;
  margin: 0;
  padding: 0;
}

.product_div {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.products_section {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
}

.search_bar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}