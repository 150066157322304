.main_section{
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  padding: 150px 0 62px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 45px;
}
.first_section{
  max-width: 60%;
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.second_section{
  box-sizing: border-box;
  padding: 0 5vw;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.second_img_section{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3%;
}
.first_img{
  width: 100%;
}

.second_imgs{
  width: 48.5%;
  cursor: pointer;
}

.section_heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;



  h2 {
    min-width: 200px;
    margin: 0 20px;
    font-weight: 800;
    font-size: 36px;
    line-height: 120%;
    /* or 48px */

    text-transform: capitalize;

    color: #FFFFFF;
  }

  hr {

    height: 1px;
    width: 100%;
    background: #FFFFFF;
    position: relative;
  }
}

.inputs_div{
  width: 100%;
  box-sizing: border-box;
  padding: 0 5vw;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.email{
  text-transform: lowercase !important;
}

.input_field_div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  label{
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #FFFFFF;

  }

  input{
    padding: 11px;

    box-sizing: border-box;

    width: 100%;
    height: 50px;

    background: #F3F3F3;
    border: 1.5px solid #E3EAEA;
    border-radius: 12px;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #000000;
  }

  textarea{
    padding: 11px;

    box-sizing: border-box;

    appearance: none;
    resize: none;
    outline: none;
    width: 100%;
    min-height: 118px;
    max-height: 118px;

    background: #F3F3F3;
    border: 1.5px solid #E3EAEA;
    border-radius: 12px;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #000000;
  }
}


.submit_btn_div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button{
    margin-top: 11px;
    box-sizing: border-box;
    padding: 8px 34px;

    border: none;
    background: #730101;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
}

.contacts_div{
  width: 100%;
  padding: 0 5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  gap: 16px;
}

.contacts_div_flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.call_number_div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  span{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
}

.cal_number_heading{
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #730101;
}

.viber_div{
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;

}

