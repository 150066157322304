.sidebar_main_div{
  width: 100%;
  min-width: 185px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}

.special_offers{
  cursor: pointer;
  height: 42px;
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  span{
    margin-left: 12px;
    position: absolute;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #730101;
  }
}

.enable_font_color{
  span {
    color: #FFFFFF !important;
  }
}

.checkbox  {
  outline: none;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  accent-color: #730101;
  border-radius: 5px;
  padding-right: 0;
  margin: 0;
}
.checkbox:checked ~ .checkmark {
  background: white;
}

.clear_filters_btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background: none;
  box-sizing: border-box;
  padding: 16px 17px;

  gap: 8px;
  font-weight: 500;
  font-size: calc(10px + 0.3vw);
  line-height: 98.9%;
  /* identical to box height, or 16px */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;


  img{
    width: 12px;
  }
}

.clear_filters_btn:hover{
  background: #730101;
  border: none;
}