.cart_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  padding: 0 7vw 0 7vw;
  box-sizing: border-box;
}

.section_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #663B20;

}

.cart_item_table_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.cart_items_table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 27px
}

.cart_item_heading_div {
  box-sizing: border-box;
  padding: 0 1vw;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.cart_item_div {

  padding: 0 1vw;
  box-sizing: border-box;

  background: #FFFFFF;
  height: 90px;
  box-shadow: -2px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 8.39381px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}


.cart_item_heading {
  display: flex;
  justify-content: center;
  align-items: center;


  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.product_image_name {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;

  span {
    width: 70%;
    text-align: start;
  }
}

.small_div {
  width: 10%;
}

.medium_div {
  width: 17.5%;
}

.big_div {
  width: 27.5%;
  justify-content: flex-start;
}


.cart_item {
  display: flex;
  justify-content: center;
  align-items: center;


  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #000000;
}

.quantity_div{
  background: none;
  text-transform: capitalize;
}
.symbol{
  color: #730101;
  font-weight: 600;
  margin-left: .5rem;
}

.product_images{
  height: 70px;
  max-width: 90%;
  border-radius: 10px;
}

.price_calculator{
  width: 45%;
}

.nav_btn_div{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


